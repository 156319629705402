.news-show {
	.header-image {
		background-repeat: no-repeat;
		background-size: cover;
		padding: 80px 0;
		height: 380px;
		h1 {
			text-shadow: 1px 1px 1px darkgrey;
		}
		@include tablet() {
			height: 536px;
			padding: 112px 0;
		}
	}

	.recommendation-frame{
		display: flex;
		justify-content: flex-end;

		@include mobile() {
			justify-content: flex-start;
		}
	}

	.recommendation-block {
		background-image: url("../img/banner_adviezen.svg");
		display: flex;
		background-repeat: no-repeat;
		background-size: cover;

		position: relative;
		min-height: 444px;
		width: 287px;

		.button {
			border-radius: 4px;
			padding: 0.5em;
		}

		.dark-overlay {
			flex-grow: 1;
			color: white;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
		}
	}

	.jubileum-label {
		background-repeat: no-repeat;
		z-index: 3;
		position: absolute;
		background-size: contain;
		background-image: url(../img/jubileum-banner.png);
		height: 125.87px;
		width: 125.87px;
		bottom: -8px;
		right: 0;

		@include mobile() {
			height: 100px;
			width: 100px;
			bottom: 15px;
		}
	}
}
.news-index {
	.subscribe-block {
		order: 2;
	}

	.column {
		&.news-item-wrapper {
			flex: none;
			width: 100%;
			order: 3;
			@include tablet() {
				width: 33.3333%;
			}
			@include desktop() {
				width: 25%;
			}
			&:nth-of-type(-n+3) {
				 order: 1;
			}
			&.not-in-search-result {
				display: none !important;
			}
		}
	}
	.is-flex-hidden {
		display:none;
	}

	.filter {
		margin-left: -1rem;
		.filter-item {
			display: inline-block;
			padding: .5rem 2rem;
			margin-left: 1rem;
			background-color: $light-grey;
			transition: background-color 500ms ease;
			&:hover {
				cursor: pointer;
				background-color: darken($light-grey, 6%);
			}

			&.active {
				background-color: $primary-lighter;

			}
		}
	}
}