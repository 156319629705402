$sides: (
		"": "",
		"t": "top",
		"b": "bottom",
		"l": "left",
		"r": "right",
		"v": "vertical",
		"h": "horizontal",
);


@each $sideName, $sideValue in $sides {
	@for $i from 0 through 10 {

		$property: if($sideName == '', '', -#{$sideValue});
		$space: $i * 8;
		$space-mobile: $i * 4;
		$selector: '';

		$selector: if($sideName == '', -#{$i}, -#{$sideValue}-#{$i});

		.has-margin#{$selector} {

			@if $sideValue == 'vertical' or $sideValue == 'horizontal' {
				@if $sideValue == 'vertical'{
					margin-top: #{$space}px !important;
					margin-bottom: #{$space}px !important;
				}

				@if $sideValue == 'horizontal'{
					margin-left: #{$space}px !important;
					margin-right: #{$space}px !important;
				}

			}@else {
				margin#{$property}: #{$space}px !important;
			}

		}



		.has-padding#{$selector} {


			@if $sideValue == 'vertical' or $sideValue == 'horizontal' {
				@if $sideValue == 'vertical'{
					padding-top: #{$space}px !important;
					padding-bottom: #{$space}px !important;
				}

				@if $sideValue == 'horizontal'{
					padding-left: #{$space}px !important;
					padding-right: #{$space}px !important;
				}

			}@else {
				padding#{$property}: #{$space}px !important;
			}
		}


	}

	@for $i from 0 through 10 {

		$property: if($sideName == '', '', -#{$sideValue});
		$space: $i * 8;
		$space-mobile: $i * 4;
		$selector: '';

		$selector: if($sideName == '', -#{$i}, -#{$sideValue}-#{$i});

		.has-margin#{$selector}-mobile {

			@if $sideValue == 'vertical' or $sideValue == 'horizontal' {
				@if $sideValue == 'vertical'{
					@include mobile {
						margin-top: #{$space-mobile}px !important;
						margin-bottom: #{$space-mobile}px !important;
					}
				}

				@if $sideValue == 'horizontal'{
					@include mobile {
						margin-left: #{$space-mobile}px !important;
						margin-right: #{$space-mobile}px !important;
					}
				}

			}@else {
				@include mobile {
					margin#{$property}: #{$space-mobile}px !important;
				}
			}

		}

		.has-padding#{$selector}-mobile {


			@if $sideValue == 'vertical' or $sideValue == 'horizontal' {
				@if $sideValue == 'vertical'{
					@include mobile {
						padding-top: #{$space-mobile}px !important;
						padding-bottom: #{$space-mobile}px !important;
					}
				}

				@if $sideValue == 'horizontal'{
					@include mobile {
						padding-left: #{$space-mobile}px !important;
						padding-right: #{$space-mobile}px !important;
					}
				}

			}@else {
				@include mobile {
					padding#{$property}: #{$space-mobile}px !important;
				}
			}
		}

	}
}

@for $i from 0 through 10 {
	$space: $i * 8 + 32  ;

	.has-size-#{$i} {
		padding: #{$space}px 24px !important;
	}

	.has-size-#{i}-mobile {
		@include mobile {
			padding: 32px 24px;
		}
	}

}