.has-radius-1 {
	border-radius: 4px;
}

.has-radius-2 {
	border-radius: 8px;
}

$sides: (
		"": "",
		"tl": "top-left",
		"tr": "top-right",
		"bl": "bottom-left",
		"br": "bottom-right",
		"t": "top",
		"b": "bottom",
		"l": "left",
		"r": "right",
);


@each $sideName, $sideValue in $sides {
	@for $i from 0 through 10 {

		$property: if($sideName == '', '-', -#{$sideValue}-);
		$space: $i * 2;
		$selector: '';

		$selector: if($sideName == '', -#{$i}, -#{$sideValue}-#{$i});

		.has-borderradius#{$selector} {

			@if $sideValue == 'top' or $sideValue == 'bottom' or $sideValue == 'left' or $sideValue == 'right' {
				@if($sideValue == 'top'){
					border-top-left-radius: #{$space}px;
					border-top-right-radius: #{$space}px;
				}
				@if($sideValue == 'bottom'){
					border-bottom-left-radius: #{$space}px;
					border-bottom-right-radius: #{$space}px;
				}
				@if($sideValue == 'left'){
					border-top-left-radius: #{$space}px;
					border-bottom-left-radius: #{$space}px;
				}
				@if($sideValue == 'right'){
					border-top-right-radius: #{$space}px;
					border-bottom-right-radius: #{$space}px;
				}
			}@else {
				border#{$property}radius: #{$space}px;
			}
		}
	}
}