.download-assets-block {
	.download-item-container:first-of-type {
		border-top: 1px solid $primary-dark;
	}
	.download-item-container {
		border-bottom: 1px solid $primary-dark;
		padding: 2px 0;
		color: $text;
		transition: background-color 1s ease;
		&:hover {
			background-color: lighten($primary-lighter, 6%);
		}
		.download-item {
			flex: 1;
			.download-item-title {
				flex: 1;
			}
		}

		i {
			color: #003B74;
		}
	}
}