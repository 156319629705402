.cookiebar {
	background-image: linear-gradient(-180deg, #78C3E2 0%, #39A9D8 100%);
	position: fixed;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 100%;

	flex-wrap: wrap;

	a {
		margin-left: auto;
	}

	.cookiebar-more {
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		margin-top: 1em;

		p {
			margin-bottom: 0.5em;
		}
	}
	.no-cookie-link {
		//background: url(/images/arrow-down-white.svg) center center no-repeat;
		//background-size: contain;
		height: 14px;
		flex: 1 0 100%;
		width: 100%;
		margin: 0 auto;
		cursor: pointer;
		text-align: center;
		font-size: 0.8em;
		text-decoration: underline;
	}

}

@include tablet {
	.cookiebar {
		flex-direction: row;
		align-items: center;
	}
	.cookiebar-more {
		flex-direction: row;
		align-items: center;
	}
}
