.over-index {
	.header-image {
		background-repeat: no-repeat;
		background-size: cover;
		padding: 80px 0;
		height: 380px;
		text-shadow: 1px 1px 1px darkgrey;
		@include tablet() {
			height: 536px;
			padding: 112px 0;
		}
	}

	.counsel {
		background-image: url("../img/wave-light.svg");
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
	}

	.card {
		position: relative;
		border-radius: 5px;
		background: #003B74;
		text-align: center;
		min-height: 300px;

		p {
			color: white;
		}

		.button {
			font-size: 16px;
			border-radius: 4px;
			padding: 0.5em;
		}

		&.secretary {
			background: #003B74;
		}
	}

	.sister_organisation_item {
		display: flex;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		position: relative;
		min-height: 464px;
		width: 100%;

		a {
			font-size: 16px;
		}

		.dark-overlay {
			flex-grow: 1;
			color: white;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
		}

	}

	.recommendation-frame{
		display: flex;
		justify-content: flex-end;

		@include mobile() {
			justify-content: flex-start;
		}
	}

	.recommendation-block {
		background-image: url("../img/banner_adviezen.svg");
		display: flex;
		background-repeat: no-repeat;
		background-size: cover;

		position: relative;
		min-height: 444px;
		width: 287px;

		.button {
			border-radius: 4px;
			padding: 0.5em;
		}

		.dark-overlay {
			flex-grow: 1;
			color: white;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
		}
	}

	img {
		border-radius: 100px;
	}
}

.over-show {
	img {
		border-radius: 4px;
	}
}