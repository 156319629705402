.search-results {

	.search-wrapper {
		padding-left: 0;
		padding-bottom: 24px;
		@include from($desktop) {
			padding-bottom: 16px;
			padding-left: 16px;
		}

		p {
			position: relative;

			input {
				padding: 4px 32px 4px 8px;
				font-size: 20px;
				width: 184px;
				height: 40px;
				border-radius: 8px;
				border: 1px solid $primary-dark;
			}

			button {
				position: absolute;
				right: 8px;
				top: 8px;
				padding: 0;
				margin: 0;
				border: none;
				font-size: 18px;
				background: none;

				&:hover {
					cursor: pointer;
				}

			}
		}
	}

	.search-item {
		&:first-of-type {
			border-top: 1px solid $primary-light;
		}
		border-bottom: 1px solid $primary-light;
		padding: 2px 0;
		color: $rich-black;
		transition: background-color 1s ease;
	}


	a {
		color: black;
	}
	span {


		&.tooltip {

			background-color: $primary-dark;
			padding: 1rem 0rem;
			margin-right: 1rem;
			width: 4rem;
			text-align: center;
			i {
				color: $white;
			}

			position: relative;
			display: inline-block;

			.tooltiptext {
				visibility: hidden;
				width: 120px;
				background-color: $primary-lighter;
				color: $rich-black;
				text-align: center;
				padding: 5px 0;
				border-radius: 6px;

				/* Position the tooltip text */
				position: absolute;
				z-index: 1;
				bottom: 80%;
				left: 50%;
				margin-left: -60px;

				/* Fade in tooltip */
				opacity: 0;
				transition: opacity 0.3s;


				&:after {
					content: "";
					position: absolute;
					top: 100%;
					left: 50%;
					margin-left: -5px;
					border-width: 5px;
					border-style: solid;
					border-color: $primary-lighter transparent transparent transparent;
				}
			}


			&:hover {
				.tooltiptext {
					visibility: visible;
					opacity: 1;
				}
			}
		}

	}

}