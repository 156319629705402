// colors
$rich-black: #050505;
$white: #FFFFFF;

$color-isabelline: #F2EFEA;

// overijssel
$color-dark-imperial-blue: #003B74;
$color-sapphire-blue: #0057A4;
$color-vivid-cerulean: #009FE3;
$color-light-cyan: #D4F3FF;

$primary: $color-sapphire-blue;
$primary-dark: $color-dark-imperial-blue;
$primary-darker: darken($primary-dark, 10%);
$primary-light: $color-vivid-cerulean;
$primary-lighter: $color-light-cyan;

$light-grey: $color-isabelline;

$custom-colors: (
	"primary-dark":($primary-dark, $primary),
	"primary-light":($primary-light, $primary),
	"primary-lighter":($primary-lighter, $primary),
);

// Body
$body-bg: $white;

// Typography
$font-family-open-sans: "open sans", sans-serif;
$family-sans-serif: $font-family-open-sans;
$body-size: 18px;
$line-height-base: 1.6;
$font-color: $rich-black;

$title-color: $font-color;
$text: $font-color;

$link: $primary-light;
$link-hover: lighten($primary-light, 20%);;

// Button
$button-border-radius: 12px;
$button-padding-horizontal: 1.5rem;

// header
$navbar-background-color: $primary-light;
$navbar-burger-color: $white;
//$navbar-item-active-background-color: $primary;
$navbar-item-active-color: $white;

//footer
$footer-background-color: $primary-dark;
$footer-padding: 2rem 1rem 2rem;

.hidden {
	display: none !important;
}