.news-item {
	display: flex;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	min-height: 315px;
	width: 100%;
	.date-range {
		font-size: 16px;
	}

	a {
		font-size: 16px;
	}

	.dark-overlay {
		flex-grow: 1;
		color: white;
		background-color: rgba(0, 0, 0, 0.3);
		width: 100%;
	}

	.jubileum-label-small {
		background-repeat: no-repeat;
		z-index: 3;
		position: absolute;
		background-size: contain;
		background-image: url(../img/jubileum-banner-small.png);
		height: 50px;
		width: 48px;
		top: -10px;
		right: 0;
	}
}