.contact {
	.header-image {
		background-repeat: no-repeat;
		background-size: cover;
		padding: 80px 0;
		height: 380px;
		text-shadow: 1px 1px 1px darkgrey;
		@include tablet() {
			height: 536px;
			padding: 112px 0;
		}

		p {
			font-size: 28px;
			max-width: 701px;
			text-shadow: none;

			@include mobile() {
				font-size: 20px;
			}

		}

	}
}