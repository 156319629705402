.is-flex{
	&.has-wrap{
		flex-wrap: wrap;
	}

	&.is-justify-between {
		justify-content: space-between;
	}

	&.is-align-items-center {
		align-items: center;
	}

	&.is-justify-content-center {
		justify-content: center;
	}

	&.is-align-items-start {
		align-items: flex-start;
	}

	&.is-align-items-end {
		align-items: flex-end;
	}

	&.is-column {
		flex-direction: column;
	}
}

.is-equal-height{
	display: flex;
	flex-direction: column;
	height: 100%;
}