.homepage {
	.header-image {
		background-repeat: no-repeat;
		background-size: cover;
		padding: 48px 0;
		height: 380px;
		h1 {
			text-shadow: 1px 1px 1px darkgrey;
			font-size: 36px;
		}
		@include tablet() {
			height: 480px;
			padding: 64px 0;
			h1 {
				font-size: 64px;
			}
		}
		@include desktop() {
			height: 536px;
			padding: 72px 0;
			h1 {
				font-size: 80px;
			}
		}
	}
	.about-block {
		background-color: #AEDEF3;

		.inside {
			//background-color: rgba(255, 255, 255, 0.6);
			background-color: #AEDEF3;
		}

		.waveline {
			width: 100vw;
		}

		.about-video-container {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			height: 0;

			iframe {
			position: absolute;
			top: 0;
			left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	.block-header-wrapper {
		display: block;
		h2 {
			line-height: 1;
		}
		@include tablet() {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}
	}
	.recommendations-block {
		.recommendation-item {
			min-height: 464px;
		}
	}
	 .news-block {
		 //background-image: url("../img/wave.svg");
		 //background-repeat: no-repeat;
		 //background-position: top center;
		 //background-size: cover;
		 background-color: #CDE9F6;

	 }
	.agenda-block {
		.agenda-item-date-header {
			width: 128px
		}
		.agenda-item-time-header {
			width: 48px
		}
		.agenda-empty-state {
			border-bottom: 1px solid $primary-dark;
			border-top: 1px solid $primary-dark;
		}
		.agenda-item-container:first-of-type {
			border-top: 1px solid $primary-dark;
		}
		.agenda-item-container {
			border-bottom: 1px solid $primary-dark;
			padding: 2px 0;
			color: $text;
			transition: background-color 1s ease;
			&:hover {
				background-color: lighten($primary-lighter, 6%);
			}
			.agenda-item {
				flex: 1;

				.agenda-item-date {
					line-height: 25px;
					color: $white;
					@include tablet() {
						width: 128px;
					}
				}
				.agenda-item-title {
					flex: 1;
				}
			}
		}
	}

}
