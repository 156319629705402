.subscribe {
  width: 100%;

  .subscribe-top{
    background-color: #AEDEF3;
    min-height: 139px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    &.report {
      width: 100%;
      display: flex;
      background-image: url("../img/advies_download_banner.png");
      background-size: cover;
    }
  }
  .subscribe-bottom{
    background-color: #0073BC;
    min-height: 158px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 2px;

    .input{
      opacity: 0.8;
      border-radius: 8px;
    }
  }

  .checkboxtext {
    font-size: 12px;
    text-align: left;
  }
}

.subscribe-frame{
  display: flex;

  @include desktop {
    justify-content: center;
  }
}