.wysiwyg-content {
	h2 {
		font-weight: 600;
		font-size: 32px;
		margin-bottom: 16px;
		line-height: 1.4;
		@include tablet() {
			font-size: 40px;
			margin-bottom: 32px;
		}
	}
	h3 {
		font-weight: 600;
		font-size: 20px;
		line-height: 1.4;
		@include tablet() {
			font-size: 24px;
		}
	}
	h4 {
		font-size: 24px;
		line-height: 1.4;
	}

	p {
		margin-bottom: 16px;
		line-height: 30px;
	}

	blockquote {
		position: relative;
		//border-radius: 5px;
		padding:40px 136px 64px 136px;
		margin: 32px 0;
		color:$white;
		text-align: center;
		background-image: url("../img/quote-bg.svg");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		p {
			font-size: 24px;
			margin-bottom: 0;
			@include desktop() {
				//font-size: 30px;
				line-height: 1.4;
			}
		}
		&:before {
			content: '"';
			font-style: italic;
			font-size: 48px;
			position: absolute;
			left: 32px;
			line-height: 0;
		}
		&:after {
			content: '"';
			font-style: italic;
			font-size: 48px;
			position: absolute;
			right: 32px;
			line-height: 0;
			bottom: 56px;
		}

		@include mobile {
			padding:40px 68px 48px 68px;
		}

	}

	figure {
		margin: 32px 0;
		img {
			max-width: 100%;
			border-radius: 4px;
		}
		figcaption {

			font-weight: 300;
			font-style: italic;
			font-size: 16px;
			color: #000000;
		}
	}

	a {
		text-decoration: none;
		color: $link;
		position: relative;
		&:active, :visited {
			color: $link;
		}
		&:hover {
			color: $link-hover;
			text-decoration: none;
		}
	}

	ol {
		margin-bottom: 16px;
		list-style-type: decimal;
		list-style-position: outside;

		padding-left: 0.5em;

		ol{
			list-style-type: lower-latin;
		}

		li {
			margin-left: 1em;
		}

	}

	ul {
		margin-bottom: 16px;
		list-style-type: none;
		list-style-position: outside;

		padding-left: 0.5em;

		li {
			margin-left: 1em;
		}

		li::before {
			content: "\2022";
			color: $primary-dark;
			font-weight: 700;
			display: inline-block;
			width: 1em;
			margin-left: -1em;
		}
	}

	.inline-video-set {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
