footer {
    p {
		color: #ffffff
    }

	a {
		display: inline-block;
		margin: 0 10px 0 0;
	  	color: #ffffff;

		:hover {
			color: #ffffff;
			opacity: 0.8;
		}
	}

  	.underline {
		border-bottom: 1px solid #ffffff;
  	}
}
