.recommendations{

	&.ongoing {

		background-image: url("../img/wave.svg");
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;

		.column {
			&.recommendation-item {
				> div {
					min-height: 450px;
				}
			}
		}

		.waveline {
			width: 100vw;
		}

	}

	.column {
		&.recommendation-item {
			min-height: 400px;
			&.not-in-search-result {
				display: none !important;
			}
		}
	}
	.is-flex-hidden {
		display:none;
	}

	.filter {
		margin-left: -1rem;
		margin-bottom: -1rem;
		.filter-item {
			display: inline-block;

			&.theme {
				@include desktop() {
					 float: left;
					 clear: left;
				 }
				//padding: .5rem 2rem;
				padding: .5rem;
				margin-left: 1rem;
				margin-bottom: 1rem;
				background-color: $light-grey;
				transition: background-color 500ms ease;
				&:hover {
					cursor: pointer;
					background-color: darken($light-grey, 6%);
				}
				&.active {
					background-color: $primary-lighter;
				}
			}
			&.year:nth-child(n+4){
				display: none;

			}
			&.year{
				@include desktop() {
					float: left;
					clear: left;
				}
				//padding: .5rem 2rem;
				padding: .5rem;
				margin-left: 1rem;
				margin-bottom: 0.5rem;

				&:hover {
					cursor: pointer;
					background-color: lighten($primary-lighter, 6%);
				}
				&.active {
					background-color: $primary-lighter;
				}

			}

			&.extra{
				@include desktop() {
					float: left;
					clear: left;
				}

				padding: .5rem;
				margin-left: 1rem;
				margin-bottom: 0.5rem;

				&:hover {
					cursor: pointer;
					//background-color: lighten($primary-lighter, 6%);
				}
				&.active {
					display: none;
				}

			}
			@include mobile() {
				display: inline-block;
			}
		}
	}
}
.recommendation-show {
	.header-image {
		background-repeat: no-repeat;
		background-size: cover;
		height: 200px;
		@include tablet() {
			height: 288px;
		}
	}

	.card {
		position: relative;
		border-radius: 5px;
		background: #D4F3FF;
		text-align: center;
		min-height: 300px;

		p {
			color: #003B74;
		}

		.button {
			font-size: 16px;
			border-radius: 4px;
			padding: 0.5em;
		}

		&.secretary {
			background: #D4F3FF;
		}

		img {
			border-radius: 100px;
		}
	}

	.related-news-item {

	}

	.download-assets-block {
		p {
			font-size: 1.5rem;
		}
	}

	.agenda-item-date-header {
		width: 128px
	}
	.agenda-item-time-header {
		width: 48px
	}
	.agenda-empty-state {
		border-bottom: 1px solid $primary-dark;
		border-top: 1px solid $primary-dark;
	}
	.agenda-item-container:first-of-type {
		border-top: 1px solid $primary-dark;
	}
	.agenda-item-container {
		border-bottom: 1px solid $primary-dark;
		padding: 2px 0;
		color: $text;
		transition: background-color 500ms ease;
		&:hover {
			background-color: lighten($primary-lighter, 6%);
		}
		.agenda-item {
			flex: 1;

			.agenda-item-date {
				line-height: 25px;
				color: $white;
				@include tablet() {
					width: 128px;
				}
			}
			.agenda-item-time {

			}
			.agenda-item-title {
				flex: 1;
			}
		}

		i {
			color: #003B74;
		}
	}
}
hiddendiv {
	display: none;
}